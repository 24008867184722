<template>
  <div class="deposit">
    <div class="deposit-tips">
      <div class="first">*{{$t('m.产品使用许可')}}：</div>
      <div>
        {{$t('m.团队缴纳相应保证金，通过官方审核后即可对用户提供量化托管服务，在项目运行期内，平台将收取：')}}
        {{$t('m.1、每托管账户不超过账户盈利10%的分成费，费用在账户退出结算时自动扣取，亏损账户无需支付；')}}
        {{$t('m.2、每日不超过&(1)的托管资金管理费。').replace('&(1)', '0.01%')}}
        {{$t('m.产品推广周期内部分或全额减免支付分成费和管理费，具体比例以平台页面为准。')}}
      </div>
      <span class="proportion">{{$t('m.当前团队需支付的账户收益分成费比例为0%，资金管理费为0%。')}}</span>
    </div>
    <!-- 保证金 -->
    <div class="team-deposit">

      <div class="team-deposit-title" v-if="TemaBond.length != 0">
        <span>{{$t('m.团队保证金')}}</span>
        <span class="remark">{{$t('m.团队初始激活保证金不低于&{1}VTN').replace('&{1}', threshold)}}</span>
      </div>

      <div class="team-deposit-box">
        <el-table :data="TemaBond" style="width: 50%" border>

          <el-table-column prop="usdtBond" :label="$t('m.初始保证金')" width="450">
            <template slot="header">
              <div class="box">
                <span>{{$t('m.保证金余额')}}</span>
                <el-tooltip class="item" effect="light" :content="tipsOne" placement="top">
                  <img src="../../assets/icon/but_popovres备份@2x.png" alt="" />
                </el-tooltip>
              </div>
            </template>
            <template slot-scope="scope">
              
              <span>{{(scope.row.usdtBond.indexOf(".") > 0 
                ? Number(scope.row.usdtBond.slice(0, scope.row.usdtBond.indexOf(".") + 5))
                : scope.row.usdtBond)+ " USDT ≈ "}}</span>
              <span style="font-size: 12px; color: #999">{{scope.row.usdtBond*scope.row.USDT_VTN + " VTN"}}</span>
              <span style="margin-left: 15px; margin-right: 15px">|</span>
              
              <span>{{scope.row.vtBond+ " VT ≈ "}}</span>
              <span style="font-size: 12px; color: #999">{{scope.row.vtBond*scope.row.VT_VTN + " VTN"}}</span>

              <span style="margin-left: 15px; margin-right: 15px">|</span>
              <span>{{ scope.row.vtnBond  + " VTN" }}</span>
            </template>
          </el-table-column>

          <el-table-column :label="$t('m.操作')">
            <template>
              <div class="deposit-operation">
                <span @click="operation('team', defaultTeam.teamId, true)">{{$t('m.补充保证金')}}</span>
                <span @click="operation('team', defaultTeam.teamId, false, TemaBond[0])">{{$t('m.提取保证金')}}</span>
                <span @click="toDetail()">{{$t('m.明细')}}</span>
              </div>
            </template>
          </el-table-column>

        </el-table>
        <div class="team-deposit-tipe"></div>
      </div>
    </div>

    <!-- 项目列表 -->
    <div class="project-list">

      <div class="project-list-title">
        <span>{{$t('m.项目保证金')}}</span>
        <img src="../../assets/icon/but_popovres备份@2x.png" alt="" @click="projectExplanation = true" />
        <span>{{$t('m.项目初始激活保证金不低于 10000VTN')}}</span>
      </div>

      <el-table :data="projectList" style="width: 100%" border>
        <el-table-column prop="projectName" :label="$t('m.项目')" width="150"></el-table-column>

        <el-table-column prop="usdtBond" :label="$t('m.保证金余额')">
          <template slot="header">
            <div class="box">
              <span>{{$t('m.保证金余额')}}</span>
              <el-tooltip class="item" effect="light" :content="tipsThree" placement="top">
                <img src="../../assets/icon/but_popovres备份@2x.png" alt="" />
              </el-tooltip>
            </div>
          </template>
          <template slot-scope="scope">
            <div>
              <div>{{Math.floor(Number(scope.row.settled) * 10000) / 10000 + " VTN "}}</div>
            </div>
          </template>
        </el-table-column>

        <el-table-column prop="frozen" :label="$t('m.未结算')" width="150">
          <template slot="header">
            <div class="box">
              <span>{{$t('m.未结算')}}</span>
              <el-tooltip class="item" effect="light" :content="tipsFour" placement="top">
                <img src="../../assets/icon/but_popovres备份@2x.png" alt="" />
              </el-tooltip>
            </div>
          </template>

          <template slot-scope="scope">
            <span>{{ Math.floor(Number(scope.row.frozen) * 10000) / 10000}}</span>
          </template>
        </el-table-column>

        <el-table-column prop="settlement" :label="$t('m.已结算')" width="150">
          <template slot="header">
            <div class="box">
              <span>{{$t('m.已结算')}}</span>
              <el-tooltip class="item" effect="light" :content="tipsFive" placement="top">
                <img src="../../assets/icon/but_popovres备份@2x.png" alt="" />
              </el-tooltip>
            </div>
          </template>

          <template slot-scope="scope">{{ Math.floor(Number(scope.row.settlement) * 10000) / 10000 }}</template>
        </el-table-column>

        <el-table-column prop="settled" :label="$t('m.可划转')" width="150">
          <template slot="header">
            <div class="box">
              <span>{{$t('m.可划转')}}</span>
              <el-tooltip class="item" effect="light" :content="tipsNine" placement="top">
                <img src="../../assets/icon/but_popovres备份@2x.png" alt="" />
              </el-tooltip>
            </div>
          </template>

          <template slot-scope="scope">{{ Transferable(scope.row) }}</template>
        </el-table-column>

        <el-table-column :label="$t('m.操作')">
          <template slot-scope="scope">
            <div class="deposit-operation">
              <span @click="transferCapital(scope.row)">{{$t('m.划转')}}</span>
              <span @click="toDetail(scope.row)">{{$t('m.明细')}}</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 二维码 -->
    <el-dialog :title="$t('m.交易授权')" :visible.sync="codeDialog" width="20%" :before-close="handleClose" :close-on-click-modal="false">
      <div class="code-warp">
        <div class="title">{{$t('m.请在Vtrading APP上确认操作或 使用APP扫描下方二维码')}}</div>

        <div class="code-box">
          <div class="qr-warp">
            <div id="qrCode" ref="qrCodeDiv"></div>
          </div>

          <div class="qr-code-invalid" v-if="!codeShow">
            <div class="warp">
              <span class="tips">{{$t('m.二维码已失效')}}</span>
              <div>
                <span class="code-btn" @click="updateCode">{{$t('m.点击刷新')}}</span>
              </div>
            </div>
          </div>
        </div>

        <span class="tips">{{ $t('m.二维码有效期') +" " + time + " s" }}</span>
      </div>
    </el-dialog>

    <el-dialog
      :title="$t('m.激活团队')"
      :visible.sync="projectInfoShow"
      width="30%"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <div class="projectInfo">
        <div class="title">
          <span>{{$t('m.团队名称')}}</span>
          <span>{{$t('m.限6个字符')}}</span>
        </div>
        <el-input v-model="name" :placeholder="$t('m.请输入内容')"></el-input>
        <div class="title" style="margin-top: 24px">
          <span>{{$t('m.团队介绍')}}</span>
          <span>{{$t('m.限500个字符')}}</span>
        </div>
        <el-input
          type="textarea"
          :rows="10"
          :placeholder="$t('m.请输入内容')"
          v-model="introduce"
          :maxlength="500"
          resize="none"
        >
        </el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confrimActivateTeam()"
          >{{$t('m.确 定')}}</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      :title="$t('m.全部项目')"
      :visible.sync="projectExplanation"
      width="30%"
      :close-on-click-modal="false"
    >
      <div class="projectExplanation">
        <p class="title">{{$t('m.余额= 初始+已结算')}}</p>
        <div class="one">
          <div>{{$t('m.强制结算')}}</div>
          <div>
            {{$t('m.余额≤0 或 (余额+未结算)≤0 时项目将被强制清算下线。')}}
            {{$t('m.实际为减少风险，当 (余额+未结算)/项目最低初始激活保证金≤20%时，将提前结算。')}}
            {{$t('m.强制结算时先结算盈利分成部分，剩余金额用于结算亏损赔付，不足以支付亏损赔付的由亏损方根据亏损权重分摊')}}
          </div>
        </div>
        <div class="tow">
          <div>{{$t('m.其他：')}}</div>
          <div>{{$t('m.用户退出时将整点结算，结算时操作员不可操作')}}</div>
          <div>{{$t('m.亏损赔付时优先使用已结算的VTN')}}</div>
          <div>{{$t('m.提取保证金时优先转出初始的USDT')}}</div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="projectExplanation = false"
          >{{$t('m.确 定')}}</el-button
        >
      </span>
    </el-dialog>

    <!--团队提取补充保证金弹窗  -->
    <el-dialog
      :visible.sync="dialogVisible"
      width="20%"
      :before-close="handleClose"
      :close-on-click-modal="false">
      <div class="bond-title">
        <span>{{ operationType ? $t('m.补充保证金') : $t('m.提取保证金') }}</span>
        <el-tooltip
          class="item"
          effect="light"
          :content="tipsEight"
          placement="top"
          v-if="operationType">
          <img src="../../assets/icon/but_popovres备份@2x.png" alt="" />
        </el-tooltip>
        <el-tooltip
          class="item"
          effect="light"
          :content="tipsSeven"
          placement="top"
          v-else>
          <img src="../../assets/icon/but_popovres备份@2x.png" alt="" />
        </el-tooltip>
      </div>
      <div>
        <template>
          <span class="currency-title">{{$t('m.请选择币种')}}</span>
          <el-select
            v-model="extractionCurrency"
            :placeholder="$t('m.请选择')"
            @change="change">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label" 
              :value="item.value">
            </el-option>
          </el-select>
          <span class="currency-title">{{$t('m.金额')}}</span>
          <el-input v-model="amount" type="number" :placeholder="$t('m.请输入金额')">
            <i class="iconStyle" slot="suffix">{{ extractionCurrency }}</i>
          </el-input>
          <span class="tips" v-if="!extractionType == 'team'">{{
            $t('m.可提：') +
            Math.floor(Number(num) * 10000) / 10000 +
            " " +
            extractionCurrency
          }}</span>
        </template>
      </div>
      <span slot="footer" class="dialog-footer">
        <span class="btn" @click="confirm()">{{$t('m.提 交')}}</span>
      </span>
    </el-dialog>

    <!-- 划转 -->
    <el-dialog
      title="划转"
      :visible.sync="transferShow"
      width="30%"
      :close-on-click-modal="false"
      :show-close="false">
      <div class="transfer-box">
        <div class="sel">
          <div class="left">
            <div class="left-box">
              <span class="title">{{$t('m.从')}}</span>
              <el-input v-model="accOne" v-if="transSort"></el-input>
              <el-select
                v-model="projectName"
                :placeholder="$t('m.请选择项目账户')"
                v-if="!transSort"
                @change="projectChange">
                <el-option
                  v-for="item in accTwo"
                  :key="item.projectName"
                  :label="item.projectName"
                  :value="item.projectName">
                </el-option>
              </el-select>
            </div>
            <div class="left-box">
              <img :src="require('../../assets/icon/zhuanhuan.png')" alt="" />
            </div>
            <div class="left-box">
              <span class="title">{{$t('m.到')}}</span>

              <el-select
                v-model="projectName"
                :placeholder="$t('m.请选择项目账户')"
                v-if="transSort"
                @change="projectChange">
                <el-option
                  v-for="item in accTwo"
                  :key="item.projectName"
                  :label="item.projectName"
                  :value="item.projectName">
                </el-option>
              </el-select>
              <el-input v-model="accOne" v-if="!transSort"></el-input>
            </div>
          </div>
          <div class="right">
            <img
              :src="require('../../assets/icon/huazhuan01.png')"
              alt=""
              @click="transSortChange()"
            />
          </div>
        </div>

        <div class="curr">
          <span class="title">{{$t('m.币种')}}</span>
          <el-select v-model="transferCurrency" :placeholder="$t('m.请选择')">
            <template v-if="transSort">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label" 
                :value="item.value">
              </el-option>
            </template>
          </el-select>
        </div>

        <div class="tran-num">
          <span class="title">{{$t('m.划转数量')}}</span>
          <el-input v-model="transferNum" :placeholder="$t('m.请输入内容')">
            <div slot="suffix" class="unit">
              <span>{{ transferCurrency }}</span>
              <span
                class="all"
                @click="transferNum = parseFloat(JSON.parse(JSON.stringify(currencyNumber)))">{{$t('m.全部')}}
              </span>
            </div>
          </el-input>
          <div class="available">
            {{
              $t('m.可用划转') + 
              " " +
              Math.floor(Number(currencyNumber) * 10000) / 10000 +
              " " +
              transferCurrency
            }}
          </div>
          <div class="tips">
            {{$t('m.转入项目的USDT将自动按汇率兑换成VTN，并在转出时原价兑成USDT')}}
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="transferCancel()">{{$t('m.取 消')}}</el-button>
        <el-button type="primary" @click="transferConfirm()">{{$t('m.划 转')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  activationTeam,
  selectTemaBond,
  selectProjectListBond,
  getBondTemporaryToken,
  getBondisSuccess,
  bondisTransfer,
} from "../../request/api";
import QRCode from "qrcodejs2";
export default {
  components: {},
  data() {
    return {
      dialogVisible: false,
      type: true, // true 补充保证金
      num: 0, //可提取
      codeDialog: false,
      codeShow: true, //二维码显示
      // tipsZero: "可以用于保证金结算的余额",

      tipsOne: this.$t('m.由超级管理员的保证金账户补充和项目保证金转入'),

      // tipsTwo:"可提取的保证金，提取此部分将自动返回保证金账户，当存在保证金项目时，不可提取保证金",

      tipsThree: this.$t('m.可用于保证金结算的余额，余额 = 转入 + 转出 + 已结算'),
      tipsFour: this.$t('m.未结算的盈利分成 + 亏损赔付 + 盈利分成费，嵌入式系统不计算盈利分成和亏损赔付'),
      tipsFive: this.$t('m.已结算的盈利分成 + 亏损赔付 + 盈利分成费 +资金管理费，嵌入式系统不计算盈利分成和亏损赔付'),

      tipsSix: this.$t('m.可提取的保证金，优先提取初始余额结余部分， 提取此部分将自动返回保证金账户，其他部分将提至收益账户，当存在保证金项目时，不可提取保证金'),
      tipsSeven: this.$t('m.优先提取初始余额结余部分，提取的USDT将自动返回保证金账户，提取的VTN将提至收益账户'),
      tipsEight: this.$t('m.从保证金账户转入，暂时只支持USDT'),
      tipsNine: this.$t('m.当项目状态为未上线、已下线时，可划转全部余额；当项目状态为审核中、已上线时，可划转 = min（余额，余额 + 未结算）- 10000 VTN；当项目状态为结算中时，可划转数量为0。'),

      defaultTeam: null,
      TemaBond: [], //团队保证金
      projectList: null,
      amount: null,
      temporaryToken: null,

      qrcode: null,
      time: 90, //二维码倒计时

      projectInfoShow: false,

      introduce: null,
      name: null,
      projectExplanation: false, //项目解释
      operationType: null,
      extractionType: null,
      extractionCurrency: "USDT",
      options: [
        {
          value: "USDT",
          label: "USDT",
        },
        {
          value: "VTN",
          label: "VTN",
        },
        {
          value: "VT",
          label: "VT",
        }
      ],
      item: null,

      transferShow: false,

      transferCurrency: "USDT", //划转币种
      transferNum: null, // 划转数
      currencyNumber: null, // 可划转数

      accOne: this.$t('m.团队账户'),
      accTwo: null,

      projectName: null, //选择的项目账户
      projectInfo: null,
      transSort: true,


      threshold:0 ,
    };
  },
  computed: {},
  watch: {
    transferCurrency(New, old) {
      if (this.transSort && New == "VTN") {
        this.currencyNumber = this.TemaBond[0].vtnBond;
      } else if (this.transSort && New == "USDT") {
        this.currencyNumber = this.TemaBond[0].usdtBond;
      }else if (this.transSort && New == "VT") {
        this.currencyNumber = this.TemaBond[0].vtBond;
      }
    },
    transferNum() {
      if (this.transferNum.indexOf(".") > 0) {
        this.transferNum = this.transferNum.slice(
          0,
          this.transferNum.indexOf(".") + 5
        );
      }
    },
    amount() {
      if (this.amount && this.amount.indexOf(".") != -1) {
        this.amount = this.amount.slice(0, this.amount.indexOf(".") + 5);
      }
    },
  },
  methods: {
    handleClose(done) {
      this.amount = null;
      this.temporaryToken = null;
      this.name = this.defaultTeam.teamName;
      this.introduce = this.defaultTeam.teamIntroduct;
      this.extractionCurrency = "USDT";
      this.item = null;
      done();
    },

    // 划转切换
    transSortChange() {
      this.transSort = !this.transSort;
      this.transferNum = null;
      if (this.transSort) {
        this.transferCurrency = "USDT";
      } else {
        this.transferCurrency = "VTN";
        this.currencyNumber = this.Transferable(this.projectInfo);
        this.currencyNumber = this.currencyNumber.split("VTN")[0];
        console.log(this.currencyNumber);
      }

      console.log(this.transSort);
      // this.currencyNumber=
      // this.projectName = null;
    },
    // 项目切换
    projectChange(v) {
      for (let key of this.accTwo) {
        if (v == key.projectName) {
          // this.projectName=v
          this.projectInfo = key;
          this.currencyNumber = this.Transferable(this.projectInfo);
        }
      }
    },

    // 取消划转
    transferCancel() {
      this.transSort = true;
      this.projectName = null;
      this.currencyNumber = null;
      this.transferNum = null;
      this.transferCurrency = "USDT";
      this.transferShow = false;
    },
    // 确定划转
    transferConfirm() {
      console.log(1);
      if (!this.transferNum) {
        this.$message.error(this.$t('m.划转数量不能为空'));
        return;
      } else if (Number(this.transferNum) <= 0) {
        this.$message.error(this.$t('m.划转数量不能小于0'));
        return;
      } else if (Number(this.transferNum) > Number(this.currencyNumber)) {
        this.$message.error(this.$t('m.划转数量不能超出可划转数'));
        return;
      }

      let payload = {
        token: sessionStorage.getItem("token"),
        teamId: this.defaultTeam.teamId,
        teamProjectId: this.projectInfo.teamProjectId,
        type: this.transSort ? "toProject" : "toTeam",
        coinType: this.transferCurrency,
        amount: this.transferNum,
      };

      console.log(payload);

      bondisTransfer(payload).then((res) => {
        if (res.status == 200) {
          console.log(res.data);
          this.selectTemaBond();
          this.selectProjectListBond();
          this.transferCancel();
        } else {
          this.$message.warning(res.msg);
        }
      });
    },

    // 划转弹窗
    transferCapital(val) {
      this.projectName = val.projectName;
      this.projectInfo = val;

      this.currencyNumber = this.TemaBond[0].usdtBond;

      this.transferShow = true;
    },

    Tips(val) {},

    // 激活团队
    activateTeam(v) {
      if (v < 10000) {
        this.$message.error(this.$t('m.团队初始激活保证金不低于 10000VTN，请补充保证金'));
        return;
      }
      this.confrimActivateTeam();
      // this.projectInfoShow = true;
    },
    // 激活团队
    confrimActivateTeam() {
      this.$confirm(this.$t('m.确定激活团队?'), this.$t('m.激活团队?'), {
        confirmButtonText: this.$t('m.确定'),
        cancelButtonText: this.$t('m.取消'),
      })
        .then(() => {
          let payload = {
            token: sessionStorage.getItem("token"),
            teamId: this.defaultTeam.teamId,
            teamName: this.name,
            teamIntroduct: this.introduce,
          };
          console.log(payload);
          activationTeam(payload).then((res) => {
            if (res.status == 200) {
              this.$message.success(this.$t('m.操作成功'));
              this.selectTemaBond();
              this.$emit("selectMessageTemaList");
              this.projectInfoShow = false;
              setTimeout(() => {
                let defaultTeam = JSON.parse(
                  sessionStorage.getItem("defaultTeam")
                );
                this.defaultTeam = defaultTeam;
              }, 500);
            } else {
              this.$message.error(res.msg);
            }
          });
        })
        .catch(() => {});
    },
    // 团队保证金
    selectTemaBond() {
      let payload = {
        token: sessionStorage.getItem("token"),
        teamId: this.defaultTeam.teamId,
      };
      selectTemaBond(payload).then((res) => {
        if (res.status == 200) {
          // this.$message.success("操作成功");
          this.TemaBond = [];
          this.TemaBond.push(res.data.TemaBond);
          this.threshold=res.data.TemaBond.threshold
          console.log(res.data);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 项目保证金列表
    selectProjectListBond() {
      let payload = {
        token: sessionStorage.getItem("token"),
        teamId: this.defaultTeam.teamId,
      };
      selectProjectListBond(payload).then((res) => {
        if (res.status == 200) {
          this.projectList = res.data.projectList;
          this.accTwo = JSON.parse(JSON.stringify(res.data.projectList));
          console.log(res.data);
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    // 团队提取/补充保证金弹窗
    operation(val, id, type, item) {

      this.type = val;
      this.extractionType = val;
      this.operationType = type;
      // this.operationType = false;
      this.temporaryId = id;
      this.dialogVisible = true;

      if (val == "team") {
        !type ? (this.num = item.usdtBond) : (this.num = null);
      } else {
        !type ? (this.num = item.usdtBond) : (this.num = null);
        this.item = item;
      }
    },
    change(v) {
      console.log(v, this.TemaBond);
      if (v == "VTN") {
        this.num = this.TemaBond[0].vtnBond;
      } else if(v == "VT") {
        this.num = this.TemaBond[0].vtBond;
      } else {
        this.num = this.TemaBond[0].usdtBond;
      }
      // if (this.item) {
      //   if (v == "VTN") {
      //     this.num = this.item.settled;
      //   } else {
      //     this.num = this.item.usdtBond;
      //   }
      // } else {

      // }
      // console.log(this.item);
      // console.log(v);
    },
    cancel() {
      this.amount = null;
      this.dialogVisible = false;
    },
    // 补充提取确定
    confirm() {

      if (Number(this.amount) <= 0) {
        this.$message.error(this.$t('m.输入金额必须大于0'));
        return;
      } else if (Number(this.amount) > this.num && !this.operationType) {
        this.$message.error(this.$t('m.输入金额不能大于可提金额'));
        return;
      }
      this.codeDialog = true;
      this.$nextTick(() => {
        this.Updated();
      });
    },

    Updated() {
      // 补充/提取 临时令牌
      let payload = {
        token: sessionStorage.getItem("token"),
        amount: Math.floor(Number(this.amount) * 10000) / 10000,
        type: this.type,
        coinType: this.extractionCurrency,
        targetId: this.temporaryId,
        strokeType: this.operationType ? "supplement" : "extract",
      };
      getBondTemporaryToken(payload).then((res) => {
        if (res.status == 200) {
          
          this.temporaryToken = res.data.temporaryToken;
          this.time = 90;
          this.codeShow = true;
          this.dialogVisible = false;
          if (this.qrcode) {
            this.qrcode.makeCode(JSON.stringify(res.data));
            this.qrcode._el.title = "";
          } else {
            this.qrcode = new QRCode(this.$refs.qrCodeDiv, {
              text: JSON.stringify(res.data),
              width: 130,
              height: 130,
              colorDark: "#333333", //二维码颜色
              colorLight: "#ffffff", //二维码背景色
              correctLevel: QRCode.CorrectLevel.L, //容错率，L/M/H
            });
            this.qrcode._el.title = "";
          }
          this.countDown();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    countDown() {
      var down = () => {
        if (this.time > 0) {
          this.time--;
          this.getBondisSuccess();
          setTimeout(down, 1000);
        } else {
          this.codeShow = false;
        }
      };
      setTimeout(down, 1000);
    },
    // 刷新二维码
    updateCode() {
      this.token = null;
      this.account = null;
      this.temporaryToken = null;
      this.Updated();
      this.countDown();
    },

    // 轮询
    getBondisSuccess() {
      if (!this.temporaryToken) {
        return;
      }
      let payload = {
        temporaryToken: this.temporaryToken,
        token: sessionStorage.getItem("token"),
      };
      getBondisSuccess(payload).then((res) => {
        if (res.status == 200 && res.data.state == true) {
          // this.$message.success("操作成功");
          this.time = 0;
          this.codeDialog = false;
          // this.projectList=null;
          // this.TemaBond=[]
          this.amount = null;
          this.selectTemaBond();
          this.selectProjectListBond();
          this.extractionCurrency = "USDT";
        } else {
          // this.$message.error(res.msg);
        }
      });
    },
    // 跳项目明细
    toSettlementDetails(id) {
      this.$router.push({ path: "settlementDetails", query: { id } });
    },

    // 保证金明细
    toDetail(val) {
      console.log(val);
      if (val) {
        this.$router.push({
          path: "depositDetail",
          query: { id: val.teamProjectId, type: "type" },
        });
      } else {
        this.$router.push({ path: "depositDetail" });
      }
    },

    // 计算可划转
    Transferable(v) {
      console.log(v);
      let val = null;
      if (v.showStatus == "notOnline") {
        val = Math.floor(Number(v.settled) * 10000) / 10000;
      } else {
        let a = Math.floor(Number(v.settled) * 10000) / 10000; //余额
        let b =
          Math.floor(Number(v.settled) * 10000) / 10000 +
          Math.floor(Number(v.frozen) * 10000) / 10000;
        let c = a > b ? b : a; //取小
        val = c - Number(v.threshold) > 0 ? c - Number(v.threshold) : 0;
      }
      return Math.floor(val * 10000) / 10000 + "VTN";
    },
  },
  created() {},
  mounted() {
    let defaultTeam = JSON.parse(sessionStorage.getItem("defaultTeam"));
    this.defaultTeam = defaultTeam;
    this.name = defaultTeam.teamName;
    this.introduce = defaultTeam.teamIntroduct;
    this.selectTemaBond();
    this.selectProjectListBond();
  },
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {},
};
</script>

<style lang='scss' scoped>
.deposit {
  margin: 30px 30px 0 0;
  padding-left: 40px;
  .deposit-tips {
    font-size: 14px;
    line-height: 20px;
    color: #333;
    background: #fff;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 8px;
    .first {
      color: #102859;
      font-size: 16px;
      margin-bottom: 30px;
      font-weight: 600;
    }
    .proportion {
      // font-size: 16px;
      display: inline-block;
      margin-top: 20px;
      // font-weight: 600;
    }
  }
  .team-deposit {
    padding: 20px;
    background: #fff;
    margin-bottom: 20px;
    border-radius: 8px;
    .team-deposit-title {
      display: flex;
      align-items: center;
      color: #333;
      margin-bottom: 15px;
      .type {
        width: 63px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #007aff;
        border-radius: 2px 8px 2px 8px;
        font-size: 12px;
        color: #fff;
        margin: 0 8px;
      }
      .remark {
        color: #999;
        font-size: 12px;
        margin-left: 10px;
      }
    }
    .team-deposit-box {
      display: flex;
      .team-deposit-tipe {
        display: flex;
        justify-content: space-around;
        font-size: 14px;
        .team-deposit-warp {
          height: 50px;
          display: flex;
          align-items: center;
          img {
            width: 12px;
            height: 12px;
            border-radius: 50%;
            margin-left: 10px;
            cursor: pointer;
          }
        }
      }
    }
  }
  .project-list {
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    .project-list-title {
      font-size: 14px;
      color: #333;
      margin-bottom: 10px;
      // :nth-child(2) {
      //   color: #999;
      //   font-size: 12px;
      //   margin: 0 8px;
      // }
      :last-child {
        color: #999;
        font-size: 12px;
        margin-left: 8px;
      }
    }
    .box {
      display: flex;
      align-items: center;
      img {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        margin-left: 5px;
        cursor: pointer;
        margin-bottom: 2px;
      }
    }
  }
  .deposit-operation {
    span {
      font-size: 12px;
      color: #007aff;
      cursor: pointer;
    }
    :nth-child(2) {
      margin: 0 30px;
    }
  }

  .tips {
    display: inline-block;
    color: #999;
    font-size: 12px;
    margin: 10px 5px;
  }
  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    color: #fff;
    background: #007aff;
    border-radius: 4px;
    cursor: pointer;
    padding: 0 15px;
    font-size: 14px;
  }
  .code-warp {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .title {
      color: #999;
      font-size: 12px;
      padding: 0 10%;
      text-align: center;
      line-height: 20px;
    }

    .tips {
      font-size: 12px;
      color: #999;
    }

    .code-box {
      position: relative;
      .qr-warp {
        padding: 5px;
        border: 1px solid #d9d9d9;
        margin: 10px 0;
      }
      .qr-code-invalid {
        margin: 10px 0;
        display: flex;
        justify-content: center;
        z-index: 100;
        position: absolute;
        top: 0;
        left: 0;
        .warp {
          width: 145px;
          height: 145px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background: url("../../assets/image/img@2x (1).png") no-repeat;
          background-size: 100% 100%;
          .tips {
            color: #fff;
            font-size: 12px;
            margin-bottom: 40px;
          }
          .code-btn {
            font-size: 12px;
            padding: 8px 20px;
            color: #fff;
            background: #2780f9;
            border-radius: 4px;
            cursor: pointer;
          }
        }
      }
    }
  }
  img {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-left: 10px;
    cursor: pointer;
  }
  .projectInfo {
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      color: #333;
      margin-bottom: 8px;
      :last-child {
        font-size: 12px;
        color: #999;
      }
    }
  }
  .projectExplanation {
    font-size: 12px;
    line-height: 20px;
    .title {
      margin-bottom: 20px;
    }
    .one {
      margin-bottom: 20px;
    }
  }
  .bond-title {
    font-size: 16px;
    color: #333;
    margin-bottom: 15px;
    img {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      margin-left: 10px;
      cursor: pointer;
    }
  }
  .el-input--suffix {
    line-height: 40px;
  }
  .el-select {
    width: 100%;
    // margin-bottom: 20px;
  }
  .currency-title {
    display: inline-block;
    font-size: 12px;
    color: #999;
    margin-bottom: 5px;
  }
}

// 划转
.transfer-box {
  width: 100%;
  .sel {
    display: flex;
    align-items: center;
    width: 100%;
    .left {
      width: -webkit-calc(100% - 30px);
      width: -moz-calc(100% - 30px);
      width: calc(100% - 30px);
      .left-box {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 10px 0;
        .title {
          margin-right: 20px;
        }
        img {
          margin: 0;
        }
      }
    }
    .right {
      img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        cursor: pointer;
      }
    }
  }
  .curr,
  .tran-num {
    margin-top: 30px;
    .title {
      display: inline-block;
      margin-bottom: 10px;
    }
    .unit {
      display: flex;
      align-items: center;
      .all {
        border-left: 2px solid #758db5;
        display: flex;
        align-items: center;
        height: 20px;
        color: #2174ff;
        margin-left: 6px;
        padding-left: 6px;
        cursor: pointer;
      }
    }
    .available {
      margin: 8px 0 10px 0;
      color: #55698a;
      font-size: 12px;
    }
    .tips {
      color: #55698a;
      font-size: 12px;
      margin: 0;
    }
  }
}
</style>